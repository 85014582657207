<template>
  <q-page class="layout-padding q-pa-md">
    <div v-if="$q.platform.is.mobile" class="flex mobile-header items-center justify-between text-weight-medium">
      <span class="text-uppercase text-faded">
        {{ $t('know_what_you_need') }}
      </span>
    </div>
    <div
      class="row list-wrap q-my-md"
    >
      <template v-for="bookable in bookables.filter(type => isTypeEnabled(type))">
        <div :key="bookable.label" class="col col-xs-6 q-my-sm text-center">
          <q-btn
            v-ripple
            :to="{ name: `ondemand-${bookable.type}`}"
            class="booking-type"
            align="left"
            :icon="`fas fa-${bookable.icon.name}`"
            :label="bookable.label"
            flat
            no-caps
          />
        </div>
      </template>
    </div>
    <router-link
      :to="{hash: '/concierge', query: { type: 'question' } }"
    >
      <template v-if="authPartner && authPartner.slug === 'derbygo'">
        <q-banner ref="banner" dense rounded class="bg-primary q-my-xs q-pa-md">
          <template v-slot:avatar>
            <q-icon name="fas fa-comments-alt" size="32px" class="q-mr-sm" />
          </template>

          <div class="text-bold">
            {{ $t('derby.need_help') }}
          </div>
          <div class="text-subtitle2">
            {{ $t('derby.contact_us') }}
          </div>
        </q-banner>
      </template>
      <template v-else>
        <q-banner ref="banner" dense rounded class="bg-primary q-my-xs q-pa-md">
          <template v-slot:avatar>
            <q-icon name="fas fa-concierge-bell" size="32px" class="q-mr-sm" />
          </template>

          <div class="text-subtitle2">
            {{ $t('cannot_find') }}
          </div>
          <div class="text-bold">
            {{ $t('concierge.always_here') }}
          </div>
        </q-banner>
      </template>
    </router-link>
  </q-page>
</template>

<script>
import authentication from 'mixins/authentication'
import travelContents from 'mixins/travelContents'
import { mapGetters } from 'vuex'

export default {
  name: 'Book',
  mixins: [authentication, travelContents],
  computed: {
    ...mapGetters({
      user: 'user',
      authPartner: 'partner',
      bookables: 'partner/bookables',
      organisation: 'organisation',
      travelPreferences: 'preferences'
    })
  }
}
</script>
<style lang="stylus">
  .booking-type
    width 90%
    background-color #0000000d
    font-size 12px
    padding 5px 0
</style>
